body {
	@apply text-colorText;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@apply font-poppins;
}

p,
li,
span,
a {
	@apply font-sans;
}
