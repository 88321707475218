.footer {
	@apply flex flex-col gap-[60px] py-4 bg-eerieBlack text-white;
	@apply lg:py-5;
}
.footerContentWrapper {
	@apply mx-auto flex flex-col gap-[35px] pt-[40px] max-w-[1440px] px-[1.2rem] sm:px-[2.4rem] md:px-[4.8rem] lg:px-[4.8rem];
	@apply sm:grid sm:grid-cols-[1.25fr,_0.6fr,_min-content] sm:gap-y-[40px];
	@apply lg:grid lg:grid-cols-[1.25fr,_0.4fr,_0.4fr,_1.25fr] lg:gap-x-[32px];
	@apply xl:gap-x-[48px];
}

.logoContainer {
	@apply relative inline-block max-w-full aspect-[17/4] h-[48px];
	@apply sm:h-[64px];
}

.firstBox p {
	@apply mb-[10px] text-balance text-[16px] leading-normal text-white-800;
	@apply ssm:text-[18px];
	@apply sm:pr-[10px];
	@apply sm:pr-[10px];
}

.firstBox ul {
	@apply mt-[20px] flex justify-start items-center gap-[18px];
}

.firstBox ul a {
	@apply flex  w-[50px] h-[50px] items-center justify-center bg-white-100 rounded-full;
	@apply hover:bg-primary;
	transition: background-color 0.5s;
}

.firstBox svg {
	transition: stroke 0.5s;
}

.footerItemPayment {
	@apply relative aspect-[40/17] w-[235.29px];
	@apply sm:h-[100px];
}

.footerItem .paymentMethods {
	@apply aspect-[40/17] h-auto w-auto;
}

.footerItem h2,
.logoContainer {
	@apply mb-[30px];
	@apply sm:mb-[16px];
}

.footerItem h2 {
	@apply text-[20px] font-semibold;
}

.footerItem ul {
	@apply flex flex-col gap-[15px] capitalize text-[16px] text-white-800 leading-normal;
	@apply ssm:text-[18px];
}

.footerItem ul a {
	@apply hover:text-white text-nowrap;
	transition: color 0.3s;
}

.footerNewsLetter input {
	@apply w-full h-[38px] bg-eerieBlack-light border-none rounded-[10px] mb-[23px] py-[28px] pl-[24px] text-[16px] leading-[1.42857];
	@apply hover:bg-eerieBlack-light-hover;
	transition: background-color 0.3s;
}

.footerNewsLetter input::placeholder {
	@apply text-white-400;
}

.footerNewsLetter input:disabled {
	@apply bg-eerieBlack-dark;
}

.footerNewsLetter button:disabled {
	@apply bg-eerieBlack-dark text-white-800 hover:text-white hover:bg-eerieBlack-dark;
}

.footerNewsLetter button {
	@apply inline-block w-auto py-[15px] px-[30px] bg-primary text-[20px] rounded-full;
	@apply hover:bg-white hover:text-eerieBlack;
	@apply focus:bg-white focus:text-eerieBlack;
	transition: background-color 0.3s;
}

.footerCopyright {
	@apply text-center;
}
