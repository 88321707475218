*,
*::after,
*::before {
	padding: 0;
	margin: 0;
	box-sizing: inherit;
}
html {
	// 10 - 12 - 14 - 16
	@apply text-[62.5%] sm:text-[68.75%] md:text-[75%];
	overflow-x: clip;
	scroll-behavior: smooth !important;
	scroll-padding: 64px;
}

body {
	box-sizing: border-box;
	overflow: clip;
	line-height: 1;
	background-color: hsl(var(--color-layout));
}

ul {
	list-style: none;
}

textarea {
	resize: none;
}

a:link,
a:visited {
	text-decoration: none;
	color: inherit;
}

// EXTRA

*:focus {
	outline: 3px solid hsl(var(--color-layout));
}

:focus:not(:focus-visible) {
	outline: none;
}
:focus-visible {
	outline: 3px solid hsl(var(--color-layout));
}

::selection {
	background-color: hsl(var(--color-primary));
	color: hsl(var(--color-secondary));
}

.grecaptcha-badge {
	visibility: hidden !important;
}

// ::-webkit-scrollbar {
// }

// ::-webkit-scrollbar-thumb {
// }

// .btn {
// 	background: none;
// 	border: none;
// }
.section {
	@apply mx-auto max-w-[1440px];
}

.section-f {
	@apply mx-auto max-w-[1440px] px-[1.2rem] sm:px-[2.4rem] md:px-[4.8rem] lg:px-[4.8rem] py-[4.8rem];
}
.section-x {
	@apply mx-auto max-w-[1440px] px-[1.2rem] sm:px-[2.4rem] md:px-[4.8rem] lg:px-[4.8rem];
}
.section-x-sm {
	@apply mx-auto max-w-[1440px] sm:px-[1.2rem] md:px-[2.4rem] lg:px-[4.8rem];
}

.section-y {
	@apply max-w-[1440px] py-[4.8rem];
}

.blog-container {
	@apply mx-auto max-w-[1124px] px-[1.2rem] sm:px-[2.4rem] md:px-[4.8rem] lg:px-[4.8rem];
}

main div:first-child .section-x,
main div:first-child .blog-container {
	padding-top: calc(6.4rem + 130px);
	padding-bottom: 6.4rem;
}
