@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../styles/main';
@layer base {
	:root {
		// primario
		--color-primary: 234deg 56% 51%;
		--color-primary-dark: 234deg 55% 45%;
		// secundario
		--color-secondary: 0deg 0% 100%;
		// botones
		--color-btn: 234deg 56% 51%;
		--color-btn-hover: 234deg 55% 45%;
		--color-btn-secondary: 0deg 0% 5%;
		--color-btn-secondary-hover: 0deg 0% 100%;
		// diseño
		--color-layout: 0deg 0% 100%;
		--color-layout-secondary: 234deg 56% 51%;
		--color-layout-tertiary: 0deg 0% 5%;
		--color-layout-complementary: 0deg 0% 5%;
		--color-icon-modal: 0deg 0% 100%;
		// texto
		--color-text: 0deg 0% 0%;
		--color-text-secondary: 0deg 0% 11%;
		--color-text-secondary-light: 0deg 0% 5%;

		// transparencia
		--color-transparency: 240deg 1% 42;
		// box shadow
		--color-shadow: 0deg 0% 15%;
		// sizes
		--header-height: 140px;

		//! La Carta
		--eerie-black-dark: 24, 24, 24;
		--eerie-black: 27, 27, 27;
		--eerie-black-800: 0deg 0% 11%;
		--eerie-black-light: 37, 37, 37;
		--eerie-black-light-hover: 45, 45, 45;

		--white: 255, 255, 255;
		--white-smoke: 244, 244, 244;
		--white-900: 255, 255, 255, 0.9;
		--white-800: 255, 255, 255, 0.8;
		--white-600: 255, 255, 255, 0.6;
		--white-400: 255, 255, 255, 0.4;
		--white-118: 255, 255, 255, 0.118;
		--white-100: 255, 255, 255, 0.1;
		--white-50: 255, 255, 255, 0.05;
		--white-20: 255, 255, 255, 0.02;

		--border: 0 0% 89.8%;
	}

	:root[data-theme='light'] {
		// primario
		--color-primary: 234deg 56% 51%;
		--color-primary-dark: 234deg 55% 45%;
		// secundario
		--color-secondary: 0deg 0% 100%;
		// botones
		--color-btn: 234deg 56% 51%;
		--color-btn-hover: 234deg 55% 45%;
		--color-btn-secondary: 0deg 0% 5%;
		--color-btn-secondary-hover: 0deg 0% 100%;
		// diseño
		--color-layout: 0deg 0% 100%;
		--color-layout-secondary: 234deg 56% 51%;
		--color-layout-tertiary: 0deg 0% 5%;
		--color-layout-complementary: 0deg 0% 5%;
		--color-icon-modal: 0deg 0% 100%;
		// texto
		--color-text: 0deg 0% 0%;
		--color-text-secondary: 0deg 0% 11%;
		--color-text-secondary-light: 0deg 0% 5%;

		// transparencia
		--color-transparency: 240deg 1% 42;
		// box shadow
		--color-shadow: 0deg 0% 15%;
		// sizes
		--header-height: 140px;

		//! La Carta
		--eerie-black-dark: 24, 24, 24;
		--eerie-black: 27, 27, 27;
		--eerie-black-800: 0deg 0% 11%;
		--eerie-black-light: 37, 37, 37;
		--eerie-black-light-hover: 45, 45, 45;

		--white: 255, 255, 255;
		--white-smoke: 244, 244, 244;
		--white-900: 255, 255, 255, 0.9;
		--white-800: 255, 255, 255, 0.8;
		--white-600: 255, 255, 255, 0.6;
		--white-400: 255, 255, 255, 0.4;
		--white-118: 255, 255, 255, 0.118;
		--white-100: 255, 255, 255, 0.1;
		--white-50: 255, 255, 255, 0.05;
		--white-20: 255, 255, 255, 0.02;

		--border: 0 0% 89.8%;
	}
	:root[data-theme='dark'] {
		// primario
		--color-primary: 234deg 56% 51%;
		--color-primary-dark: 234deg 55% 45%;
		// secundario
		--color-secondary: 0deg 0% 100%;
		// botones
		--color-btn: 234deg 56% 51%;
		--color-btn-hover: 234deg 55% 45%;
		--color-btn-secondary: 0deg 0% 5%;
		--color-btn-secondary-hover: 0deg 0% 100%;
		// diseño
		--color-layout: 0deg 0% 10.6%;
		--color-layout-secondary: 234deg 56% 51%;
		--color-layout-tertiary: 0deg 0% 11%;
		--color-layout-complementary: 0deg 0% 100%;
		--color-icon-modal: 0deg 0% 5%;
		// texto
		--color-text: 0deg 0% 100%;
		--color-text-secondary: 222deg 17% 65%;
		--color-text-secondary-light: 222deg 17% 70%;

		// box shadow
		--color-shadow: 0deg 0% 15%;
		// tamaño fuente
		// transparencia
		--color-transparency: 240deg 1% 42%;
		// sizes
		--header-height: 140px;

		//! La Carta
		--eerie-black-dark: 24, 24, 24;
		--eerie-black: 21, 21, 21;
		--eerie-black-800: 0deg 0% 11%;
		--eerie-black-light: 37, 37, 37;
		--eerie-black-light-hover: 45, 45, 45;

		--white: 255, 255, 255;
		--white-smoke: 244, 244, 244;
		--white-900: 255, 255, 255, 0.9;
		--white-800: 255, 255, 255, 0.8;
		--white-600: 255, 255, 255, 0.6;
		--white-400: 255, 255, 255, 0.4;
		--white-118: 255, 255, 255, 0.118;
		--white-100: 255, 255, 255, 0.1;
		--white-50: 255, 255, 255, 0.05;
		--white-20: 255, 255, 255, 0.02;

		--border: 0 0% 89.8%;
	}
}
