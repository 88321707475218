@keyframes appear {
	from {
		opacity: 0;
		transform: translateY(-100px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes appearOnlyOpacity {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
